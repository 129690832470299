@import ./variables.style

.landing
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow-y: hidden
    overflow-x: hidden
    background-color: rgba(128,128,128, 0.15)

.landing-overflow
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow-y: auto
    overflow-x: hidden
    background-color: rgba(128,128,128, 0.15)

.header
    position: absolute
    top: 0
    height: 8%
    left: 0
    width: 100%
    background-color: #fff
    img
        position: absolute
        height: 60%
        top: 50%
        left: 2%
        transform: translate(0, -50% )

.background
    position: absolute
    height: 110%
    right: 0
    top: 28%
    transform: translate(37%,0)

.landing-content
    position: absolute
    width: 45%
    height: auto
    top: 15%
    left: 11%

.umbra-group-container
    position: relative
    top: 0
    left: 0
    width: 100%
    height: 10vh
    margin-bottom: 2vw
    img
        position: absolute
        max-width: 28%
        min-height: 100%
        max-height: 100%
        object-fit: contain
        width: auto
        left: 50%
        transform: translate(-50%,0)

.other-logos-container
    position: relative
    left: 0
    width: 100%
    height: 12vh
    margin-bottom: 2vw

.logo-3d
    position: absolute
    max-width: 20%
    min-width: 20%
    max-height: 100%
    min-height: 100%
    object-fit: contain
    top: 50%
    left: 15%
    transform: translate(50%,-50%)
    img
        position: absolute
        top: 50%
        transform: translate(0,-50%)
        max-width: 100%
        min-width: 100%
        max-height: 100%
        min-height: 100%
        object-fit: contain

.logo-int
    position: absolute
    max-width: 20%
    min-width: 20%
    max-height: 100%
    min-height: 100%
    object-fit: contain
    top: 50%
    right: 15%
    transform: translate(-50%,-50%)
    img
        position: absolute
        top: 50%
        transform: translate(0,-50%)
        max-width: 100%
        min-width: 100%
        max-height: 100%
        min-height: 100%
        object-fit: contain

.landing-text-first
    position: relative
    height: auto

.title
    font-family: $font-family-regular
    color: #808080
    font-size: 4vh
    margin-bottom: 2vw

.text
    font-family: $font-family-light
    color: #000
    font-size: 2.2vh
    margin-bottom: 2vw

.read-more
    font-family: $font-family-light
    cursor: pointer
    color: #ED0E74
    font-size: 2.2vh
    margin-bottom: 2vw
    width: auto

.read-more:hover
    text-shadow:2px 2px 5px #ED0E74
    font-weight: bolder

.landing-text-second
    position: relative
    height: auto
    margin-bottom: 5%

.signature-container
    margin-top: 10%
    position: relative
    width: 100%
    height: 30vh

.carlos-signature
    position: absolute
    width: 30%
    left: 0%
    img
        width: 100%

.diana-signature
    position: absolute
    width: 30%
    left: 42%
    img
        width: 100%

.divisor
    margin-top: 5%
    margin-bottom: 5%
    width: 60%
    height: 0.2vh
    background-color: #ED0E74

.name
    font-family: $font-family-light
    color: #000
    font-size: 2.2vh

.position
    font-family: $font-family-light
    color: #000
    font-size: 2.2vh

.footer
    position: absolute
    bottom: 0
    height: 4%
    left: -24.5%
    width: 100vw
    background-color: #fff
    img
        position: absolute
        height: 60%
        top: 50%
        right: 2%
        transform: translate(0, -50% )
    div
        position: absolute
        top: 50%
        left: 11%
        transform: translate(0, -50%)
        font-family: $font-family-light
        color: #000
        font-size: 1.8vh
.header
    img
        height: 50%
        left: 5%

.landing-content
    width: 90%
    left: 5%
    top: 10%

.umbra-group-container
    height: 8vh

.other-logos-container
    a
        max-width: 30%
        min-width: 30%
        transform: translate(0%,-50%)

.background
    top: 50%
    transform: translate(50%,0)

.title
    margin-bottom: 2vh

.text
    margin-bottom: 2vh

.carlos-signature
    width: 40%

.diana-signature
    width: 45%
    left: 50%

.footer
    height: 3%
    left: -6%
    img
        height: 50%
    div
        left: 5%
        font-size: 1.2vh 
* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow-x: hidden;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

strong {
  font-weight: bolder;
  font-family: "Lato Regular";
}

.fade-out {
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.fade-in {
  pointer-events: all;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.main-landing {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  color: #000;
  text-decoration: none;
}

@font-face {
  font-family: "Lato Regular";
  src: url(./fonts/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Lato Black";
  src: url(./fonts/Lato-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Lato Bold";
  src: url(./fonts/Lato-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Lato Light";
  src: url(./fonts/Lato-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Lato Thin";
  src: url(./fonts/Lato-Thin.ttf) format("truetype");
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ED0E74;
}

.landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgba(128, 128, 128, 0.15);
}

.landing-overflow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(128, 128, 128, 0.15);
}

.header {
  position: absolute;
  top: 0;
  height: 8%;
  left: 0;
  width: 100%;
  background-color: #fff;
}
.header img {
  position: absolute;
  height: 60%;
  top: 50%;
  left: 2%;
  transform: translate(0, -50%);
}

.background {
  position: absolute;
  height: 110%;
  right: 0;
  top: 28%;
  transform: translate(37%, 0);
}

.landing-content {
  position: absolute;
  width: 45%;
  height: auto;
  top: 15%;
  left: 11%;
}

.umbra-group-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  margin-bottom: 2vw;
}
.umbra-group-container img {
  position: absolute;
  max-width: 28%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0);
}

.other-logos-container {
  position: relative;
  left: 0;
  width: 100%;
  height: 12vh;
  margin-bottom: 2vw;
}

.logo-3d {
  position: absolute;
  max-width: 20%;
  min-width: 20%;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
  top: 50%;
  left: 15%;
  transform: translate(50%, -50%);
}
.logo-3d img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
}

.logo-int {
  position: absolute;
  max-width: 20%;
  min-width: 20%;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
  top: 50%;
  right: 15%;
  transform: translate(-50%, -50%);
}
.logo-int img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
}

.landing-text-first {
  position: relative;
  height: auto;
}

.title {
  font-family: "Lato Regular";
  color: #808080;
  font-size: 4vh;
  margin-bottom: 2vw;
}

.text {
  font-family: "Lato Light";
  color: #000;
  font-size: 2.2vh;
  margin-bottom: 2vw;
}

.read-more {
  font-family: "Lato Light";
  cursor: pointer;
  color: #ED0E74;
  font-size: 2.2vh;
  margin-bottom: 2vw;
  width: auto;
}

.read-more:hover {
  text-shadow: 2px 2px 5px #ED0E74;
  font-weight: bolder;
}

.landing-text-second {
  position: relative;
  height: auto;
  margin-bottom: 5%;
}

.signature-container {
  margin-top: 10%;
  position: relative;
  width: 100%;
  height: 30vh;
}

.carlos-signature {
  position: absolute;
  width: 30%;
  left: 0%;
}
.carlos-signature img {
  width: 100%;
}

.diana-signature {
  position: absolute;
  width: 30%;
  left: 42%;
}
.diana-signature img {
  width: 100%;
}

.divisor {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 60%;
  height: 0.2vh;
  background-color: #ED0E74;
}

.name {
  font-family: "Lato Light";
  color: #000;
  font-size: 2.2vh;
}

.position {
  font-family: "Lato Light";
  color: #000;
  font-size: 2.2vh;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 4%;
  left: -24.5%;
  width: 100vw;
  background-color: #fff;
}
.footer img {
  position: absolute;
  height: 60%;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
}
.footer div {
  position: absolute;
  top: 50%;
  left: 11%;
  transform: translate(0, -50%);
  font-family: "Lato Light";
  color: #000;
  font-size: 1.8vh;
}

@media only screen and (orientation: portrait) and (max-width: 520px) {
  .header img {
    height: 50%;
    left: 5%;
  }

  .landing-content {
    width: 90%;
    left: 5%;
    top: 10%;
  }

  .umbra-group-container {
    height: 8vh;
  }

  .other-logos-container a {
    max-width: 30%;
    min-width: 30%;
    transform: translate(0%, -50%);
  }

  .background {
    top: 50%;
    transform: translate(50%, 0);
  }

  .title {
    margin-bottom: 2vh;
  }

  .text {
    margin-bottom: 2vh;
  }

  .carlos-signature {
    width: 40%;
  }

  .diana-signature {
    width: 45%;
    left: 50%;
  }

  .footer {
    height: 3%;
    left: -6%;
  }
  .footer img {
    height: 50%;
  }
  .footer div {
    left: 5%;
    font-size: 1.2vh;
  }
}
@media only screen and (orientation: landscape) and (max-device-width: 1024px) {
  .title {
    font-size: 2.5vw;
  }

  .text {
    font-size: 1.8vw;
  }

  .name {
    font-size: 1.5vw;
  }

  .position {
    font-size: 1.5vw;
  }

  .footer img {
    height: 40%;
  }

  .landing-text-second {
    margin-bottom: 10%;
  }
}
@media only screen and (orientation: portrait) and (max-width: 450px) {
  .landing-content {
    width: 70%;
  }

  .footer {
    left: -16.5%;
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (min-width: 700px) and (max-width: 960px), (min-resolution: 192dpi) and (min-width: 700px) and (max-width: 960px) {
  .landing-content {
    width: 70%;
  }

  .footer {
    left: -16.5%;
  }
}


@import ./variables.style

*
    margin: 0
    padding: 0
    box-sizing: 0

html, body
    width: 100%
    height: 100%
    margin: 0
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -o-user-select: none
    user-select: none
    overflow-x: hidden

img
    -webkit-user-drag: none
    -khtml-user-drag: none
    -moz-user-drag: none
    -o-user-drag: none
    user-drag: none
    pointer-events: none

#root
    width: 100%
    height: 100%
    overflow-x: hidden
    overflow-y: auto

strong
    font-weight: bolder
    font-family: $font-family-regular

.fade-out
    pointer-events: none
    opacity: 0
    transition: all 0.3s ease-in-out

.fade-in
    pointer-events: all
    opacity: 1
    transition: all 0.3s ease-in-out

.main-landing
    width: 100%
    height: 100%
    overflow-x: hidden
    overflow-y: auto

a
    color: #000
    text-decoration: none
    
@import ./fonts.style
@import ./scroll.style
@import ./landing.style

@media only screen and (orientation: portrait) and (max-width: 520px)
    @import ./responsive/portrait.style

@media only screen and (orientation: landscape) and (max-device-width: 1024px)
    @import ./responsive/landscape.style
    
@media only screen and (orientation: portrait) and (max-width: 450px) 
    @import ./responsive/desktop.style

@media (-webkit-min-device-pixel-ratio: 2) and (min-width: 700px) and (max-width: 960px),  (min-resolution: 192dpi) and (min-width: 700px) and (max-width: 960px)
    @import ./responsive/desktop.style
@import ./variables.style

@font-face 
    font-family: $font-family-regular
    src: url(./fonts/Lato-Regular.ttf) format("truetype")

@font-face 
    font-family: $font-family-black
    src: url(./fonts/Lato-Black.ttf) format("truetype")

@font-face 
    font-family: $font-family-bold
    src: url(./fonts/Lato-Bold.ttf) format("truetype")

@font-face 
    font-family: $font-family-light
    src: url(./fonts/Lato-Light.ttf) format("truetype")

@font-face 
    font-family: $font-family-thin
    src: url(./fonts/Lato-Thin.ttf) format("truetype")
.title
    font-size: 2.5vw

.text
    font-size: 1.8vw

.name
    font-size: 1.5vw

.position
    font-size: 1.5vw

.footer
    img
        height: 40%

.landing-text-second
    margin-bottom: 10%